//点击注册
$('.third_party_registered_register span').click(function(e){
    e.stopPropagation();
    third_party_registered_fn(0);
});
//绑定google账号
$('.third_party_registered_btn').click(function(e){
    e.stopPropagation();
    third_party_registered_fn(21607);
});
