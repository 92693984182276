
// google
$('.google_a').click(function(e){
    e.stopPropagation();
    if(!isnetwork()){return false;};
    var link=location.origin+"/"
    $.post(
        post_url+'account/google/oauth',
        {
            e_id:murmur,
            a:link,
            b:location.origin+location.pathname+'?3rdGoogle=error'
        },
        function(data){
            console.log(data);
            error_fn(data.error);
            switch(data.error){
                case 0:
                    setCookie('google_key',data.key,90);
                    location.href=data.link;
                    break;
                case 26601:
                    error(i18n[lang].error_26601);
                    break;
                case 26602:
                    error(i18n[lang].error_26602);
                    break;
                case 26603:
                    error(i18n[lang].error_26603);
                    break;
                case 26604:
                    error(i18n[lang].error_26604);
                    break;
                case 26605:
                    error(i18n[lang].error_26605);
                    break;
            }
        }
    ).error(function(xhr){
        overtime(26699);
    })
});
// 当前页面打开google打开
//google判断是否登录成功
// (function(){
//     var google_id_token;
//     var query = location.hash;
//     var vars = query.split("&");
//     for (var i=0;i<vars.length;i++) {
//             var pair = vars[i].split("=");
//             if(pair[0] == 'id_token'){google_id_token=pair[1];}
//     }
//     console.log(google_id_token);
//     if(google_id_token){
//         $.get('https://www.googleapis.com/oauth2/v3/tokeninfo?id_token='+google_id_token,function(reg){
//             localStorage.setItem('google_id_token',google_id_token);
//             localStorage.setItem('google_email',reg.email);
//             console.log(reg);
//             $.get('https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token='+google_id_token,function(reg){
//                 console.log(reg);
//             });
//             // google_login()
//         });
//     }
// })();
$('.facebook_a').click(function(e){
    e.stopPropagation();
    if(!isnetwork()){return false;};
    $.getScript("https://connect.facebook.net/en_US/sdk.js",function(){
        // facebook
        window.fbAsyncInit = function() {
            FB.init({
                appId      : '330072601647659',
                cookie     : true,
                xfbml      : true,
                version    : 'v9.0'
            });
        };
        //登录
        FB.login(function(response) {
            if (response.status === 'connected') {
                FB.api('/me', function() {
                    setCookie('user_facebook',JSON.stringify({'facebook_id_token':response.authResponse.accessToken}),90);
                    let that=$('.login .login_btn');
                    $.post(
                        post_url+'account/facebook/login',
                        {
                            e_id:murmur,
                            access_token:response.authResponse.accessToken,
                        },
                        function(data){
                            console.log(data);
                            error_fn(data.error,that);
                            switch(data.error){
                                case 0:
                                    setCookie('user',JSON.stringify({'e_id':murmur,'t_id':data.t_id,'token':data.token}),90);
                                    location.href=localStorage.getItem('source');
                                    break;
                                case 22201:
                                    error(i18n[lang].error_22201);
                                    break;
                                case 22202:
                                    error(i18n[lang].error_22202);
                                    break;
                                case 22203:
                                    error(i18n[lang].error_22203);
                                    break;
                                case 22204:
                                    error(i18n[lang].error_22204);
                                    break;
                                case 22205:
                                    // 不提示，自动去注册一个3方账号并登录
                                    //自动帮忙注册一个账号
                                    $.post(
                                        post_url+'account/facebook/register',
                                        {
                                            e_id:murmur,
                                            access_token:response.authResponse.accessToken,
                                        },
                                        function(data){
                                            console.log(data);
                                            error_fn(data.error,that);
                                            switch(data.error){
                                                case 0:
                                                    setCookie('user',JSON.stringify({'e_id':murmur,'t_id':data.t_id,'token':data.token}),90);
                                                    location.href="/3rd-account-connect?3rd-party=facebook";
                                                    break;
                                                case 22401:
                                                    error(i18n[lang].error_22401);
                                                    break;
                                                case 22402:
                                                    error(i18n[lang].error_22402);
                                                    break;
                                                case 22403:
                                                    error(i18n[lang].error_22403);
                                                    break;
                                                case 22404:
                                                    error(i18n[lang].error_22404);
                                                    break;
                                                case 22405:
                                                    error(i18n[lang].error_22405);
                                                    break;
                                                    // 不提示，重新三方登录到新的账号上
                                                case 22406:
                                                    error(i18n[lang].error_22406);
                                                    break;
                                                case 22407:
                                                    error(i18n[lang].error_22407);
                                                    break;
                                                case 22408:
                                                    error(i18n[lang].error_22408);
                                                    break;
                                                case 22409:
                                                    error(i18n[lang].error_22409);
                                                    break;
                                                case 22410:
                                                    error(i18n[lang].error_22410);
                                                    break;
                                                case 22411:
                                                    error(i18n[lang].error_22411);
                                                    break;
                                                case 22412:
                                                    error(i18n[lang].error_22412);
                                                    break;
                                                case 22413:
                                                    error(i18n[lang].error_22413);
                                                    break;
                                                case 22414:
                                                    error(i18n[lang].error_22414);
                                                    break;
                                                case 22415:
                                                    error(i18n[lang].error_22415);
                                                    break;
                                                case 22416:
                                                    error(i18n[lang].error_22416);
                                                    break;
                                                case 22417:
                                                    error(i18n[lang].error_22417);
                                                    break;
                                                case 22418:
                                                    error(i18n[lang].error_22418);
                                                    break;
                                                case 22419:
                                                    error(i18n[lang].error_22419);
                                                    break;
                                                case 22420:
                                                    error(i18n[lang].error_22420);
                                                    break;
                                                case 22421:
                                                    error(i18n[lang].error_22421);
                                                    break;
                                                case 22422:
                                                    error(i18n[lang].error_22422);
                                                    break;
                                                case 22423:
                                                    error(i18n[lang].error_22423);
                                                    break;
                                                case 22424:
                                                    error(i18n[lang].error_22424);
                                                    break;
                                                case 22425:
                                                    error(i18n[lang].error_22425);
                                                    break;
                                            }
                                        }
                                    ).error(function(xhr){
                                        overtime(22499);
                                    })
                                    break;
                                case 22206:
                                    error(i18n[lang].error_22206);
                                    break;
                            }
                        }
                    ).error(function(xhr){
                        overtime(22299);
                    })
                });
            } else {
                error(i18n[lang].error_22498);
            }
        }, {scope: 'public_profile,email'});
    });
});