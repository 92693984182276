//记住登录页的邮箱,到忘记密码使用
$('.member .email').change(function(){
    localStorage.setItem('login_email',$(this).val());
});
//点击登陆
$('.login .login_btn').click(function(e){
    e.stopPropagation();
    autho_success($(this));
});
//免密登陆
//注册邮箱获取验证码
$('.passless_login .getcode').click(function(e){
    e.stopPropagation();
    if(!isnetwork()){return false;};
    let that=$(this);
    let email=that.parents('.passless_login').find('.email').val();
    if(!email){
        //判断是否邮箱为空
        that.parents('.passless_login').find('.email').addClass('active');
        that.parents('.passless_login').find('.email').next().html(i18n[lang].email_empty);
        return false;
    }else if(!reg_email.test(email)){
        //正则判断格式是否正确
        that.parents('.passless_login').find('.email').addClass('active');
        that.parents('.passless_login').find('.email').next().html(i18n[lang].email_not_valid);
        return false;
    }else if(that.attr('class').indexOf('nobind')>0){
        return false;
    }else{
        that.addClass('nobind');
        that.next().html('');
        that.html('<img src="https://account.mindonmap.com/images/loading-h.svg" alt="Loading">');
        //发送请求
        $.post(
            post_url+"account/authcode/email/passless",
            {
                e_id:murmur,
                email:email,
                language:lang.toLowerCase()
            },
            function(data){
                that.prev().removeAttr('readonly');
                that.prev().css('cursor','auto');
                sendCode(that,true);
                error_fn(data.error,that);
                switch(data.error){
                    case 0:
                        that.parents('.passless_login').find('.code').next().next().html(no_code_text);
                        break;
                    case 20501:
                        that.parents('.passless_login').find('.email').addClass('active');
                        that.parents('.passless_login').find('.email').next().html(i18n[lang].error_20501);
                        break;
                    case 20502:
                        that.parents('.passless_login').find('.email').addClass('active');
                        that.parents('.passless_login').find('.email').next().html(i18n[lang].error_20502);
                        break;
                    case 20503:
                        that.parents('.passless_login').find('.code').next().next().html(i18n[lang].error_20503);
                        break;
                    case 20504:
                        that.parents('.passless_login').find('.code').next().next().html(i18n[lang].error_20504);
                        break;
                }
                if(data.error!=0){
                    sendCode(that,false);
                }
            }
        ).error(function(xhr){
            that.prev().removeAttr('readonly');
            that.prev().css('cursor','pointer');
            sendCode(that,false);
            overtime(20599);
        });
    };
});
//点击登陆
$('.passless_login .passless_login_btn').click(function(e){
    e.stopPropagation();
    passless_login_btn_fn($(this));
})
function passless_login_btn_fn(element,callback){
    if(!isnetwork()){return false;};
    let that=element;
    console.log(that);
    let email=that.parents('.passless_login').find('.email').val();
    let code=that.parents('.passless_login').find('.code').val();
    if(!email){
        //判断邮箱是否为空
        that.parents('.passless_login').find('.email').addClass('active');
        that.parents('.passless_login').find('.email').next().html(i18n[lang].email_empty);
        return false;
    }else if(!reg_email.test(email)){
        //正则判断格式是否正确
        that.parents('.passless_login').find('.email').addClass('active');
        that.parents('.passless_login').find('.email').next().html(i18n[lang].email_not_valid);
        return false;
    }else if(!code){
        //判断验证码是否为空
        that.parents('.passless_login').find('.code').next().next().html(i18n[lang].code_empty);
        return false;
    }else if(!reg_code.test(code)){
        //判断验证码格式是否正确
        that.parents('.passless_login').find('.code').next().next().html(i18n[lang].code_not_valid);
        return false;
    }else{
        that.find('img').css('opacity',1);
        //发送请求
        $.post(
            post_url+"account/email/passless",
            {
                e_id:murmur,
                email:email,
                authcode:code
            },
            function(data){
                console.log(data);
                that.find('img').css('opacity',0);
                error_fn(data.error,that);
                switch(data.error){
                    case 0:
                        setCookie('user',JSON.stringify({'e_id':murmur,'t_id':data.t_id,'token':data.token}),90);
                        if(callback){
                            callback();
                            return false;
                        }
                        $.post(
                            post_url+'account/profile',
                            {
                                e_id:murmur,
                                t_id:JSON.parse(getCookie('user')).t_id,
                                token:JSON.parse(getCookie('user')).token
                            },
                            function(data){
                                if(data.has_password){
                                    location.href=localStorage.getItem('source');
                                }else{
                                    location.href="/passwordless-login-no-password/";
                                }
                            }
                        ).error(function(xhr){
                            overtime(20999);
                        });
                        break;
                    case 20601:
                        that.parents('.passless_login').find('.email').addClass('active');
                        that.parents('.passless_login').find('.email').next().html(i18n[lang].error_20601);
                        break;
                    case 20602:
                        that.parents('.passless_login').find('.code').next().next().html(i18n[lang].error_20602);
                        break;
                    case 20603:
                        that.parents('.passless_login').find('.email').addClass('active');
                        that.parents('.passless_login').find('.email').next().html(i18n[lang].error_20603);
                        break;
                    case 20604:
                        that.find('p').html(i18n[lang].error_20604);
                        break;
                    case 20605:
                        that.parents('.passless_login').find('.code').next().next().html(i18n[lang].code_not_valid);
                        break;
                    case 20606:
                        that.find('p').html(i18n[lang].error_20606);
                        break;
                    case 20607:
                        that.find('p').html(i18n[lang].error_20607);
                        break;
                    case 20608:
                        that.find('p').html(i18n[lang].error_20608);
                        break;
                }
            }
        ).error(function(xhr){
            that.find('img').css('opacity',0);
            overtime(20699);
        });
    };
}
